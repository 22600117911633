import logo from './logo.svg';
import React from 'react';

//import Navbar from './component/Navbar';
//<header className="App-header">
//        <Navbar />
//      </header>
function App() {
  return (
    <div className="App">
      
      <div className="text-center mx-auto"> 
        <img src={logo} className="App-logo mx-auto mt-10 mb-10" alt="logo" style={{ width: '400px' }} />
        <h1 className='w-full text-3xl font-bold text-white text-center'>buck.club</h1>
      </div>
    </div>
  );
}


export default App;
